import React from "react"
import { Col } from "react-bootstrap"
import DesktopNav from "./DesktopNav"
import { ReactComponent as Logo } from "../images/logo.svg"

const DesktopNavColumn = (props) => {
  return (
    <Col className="blueCol d-flex flex-column" lg={6}>
      <div>
        <Logo className="logo" />
        <DesktopNav handleEnter={props.handleEnter} headings={props.headings} />
      </div>
    </Col>
  )
}

export default DesktopNavColumn
