import $ from "jquery";

//Changing view-unit based on aspect ratio for better responsiveness
function responsiveViewUnit() {
  if (window.innerWidth / window.innerHeight >= 3.8 / 5) {
    $(":root").css("--view-unit", "0.8vh");
    $(".nav-btn").css("line-height", "1.22");
  }
  if (window.innerWidth / window.innerHeight < 3.8 / 5) {
    $(":root").css("--view-unit", "1vw");
  }

  window.addEventListener("resize", () => {
    if (window.innerWidth / window.innerHeight >= 3.8 / 5) {
      $(":root").css("--view-unit", "0.8vh");
      $(".nav-btn").css("line-height", "1.22");
    }
    if (window.innerWidth / window.innerHeight < 3.8 / 5) {
      $(":root").css("--view-unit", "1vw");

      $(".nav-btn").css("line-height", "1.1");
    }
  });
}

export default responsiveViewUnit;
