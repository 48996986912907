import React from "react";
import { Col } from "react-bootstrap";
import _ from "lodash";

const DesktopContentCol = (props) => {
  return (
    <Col className="brownCol" lg={6}>
      <div className="content-col">
        {props.headings.map((heading, i) => {
          return (
            <div
              className={`slide ${i === 0 && "first-slide"}`}
              id={_.kebabCase(heading)}
              data-slide={i}
              key={i}
            >
              {props.content[i]}
            </div>
          );
        })}
      </div>
    </Col>
  );
};

export default DesktopContentCol;
