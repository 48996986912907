import React from "react";
import { Row, Button } from "react-bootstrap";
import _ from "lodash";

const MobileSlideTemplate = (props) => {
  return (
    <Row className={props.rowClass}>
      <div
        className={`slide ${props.i === 1 && "scrollTrig"}`}
        id={_.kebabCase(props.heading)}
        data-slide={props.i}
      >
        <Button className="nav-btn" variant="link" data-index={props.i}>
          {props.heading}
        </Button>
        {props.content}
      </div>
    </Row>
  );
};

export default MobileSlideTemplate;
