import React from "react"
import { Button } from "react-bootstrap"
import { ReactComponent as UpArrow } from "../images/upArrow.svg"
import { useMediaQuery } from "react-responsive"

const Content0 = () => {
  const isUnderLg = useMediaQuery({ query: "(max-width: 991.8px)" })
  return (
    <>
      <p>
        Latrán 193,
        <br />
        381 01 Český Krumlov
      </p>
      <div className="gmap_canvas">
        <iframe
          title="google-map"
          id="gmap_canvas"
          src="https://google.com/maps?q=Advok%C3%A1tn%C3%AD%20kancel%C3%A1%C5%99%20JUDr.%20Pavel%20Mar%C5%A5%C3%A1n,%20Mgr.%20Radka%20Mar%C5%A5%C3%A1nov%C3%A1&t=&z=15&ie=UTF8&iwloc=&output=embed"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
        ></iframe>
      </div>
      <Button
        className="gmap-btn"
        href="https://www.google.com/maps/place/Advok%C3%A1tn%C3%AD+kancel%C3%A1%C5%99+JUDr.+Pavel+Mar%C5%A5%C3%A1n,+Mgr.+Radka+Mar%C5%A5%C3%A1nov%C3%A1/@48.8141189,14.3173382,17z/data=!3m1!4b1!4m5!3m4!1s0x47735d758c14234b:0xa5a9c2529884ca81!8m2!3d48.8141149!4d14.3172361"
        variant="primary"
        target="_blank"
      >
        Mapy Google
      </Button>
      {isUnderLg && (
        <a href="/#">
          <div className="up-arrow-div">
            <UpArrow className="up-arrow"></UpArrow>
          </div>
        </a>
      )}
    </>
  )
}
const Content1 = () => {
  return (
    <>
      advokát
      <br />
      ČAK 8782
      <br />
      <br />
      tel. 776 719 708
      <br />
      email: martan@ak-martan.cz
      <hr />
      právní oblasti:
      <ul>
        <li>
          <b>občanské právo</b> - zastupování v soudních sporech, smluvní právo,
          převody nemovitostí, dědické právo apod.
        </li>
        <li>
          <b>pracovní právo</b>
        </li>
        <li>
          <b>trestní právo</b>
        </li>
        <li>
          <b>firemní právo</b>
        </li>
        <li>
          <b>správní právo</b>
        </li>
      </ul>
    </>
  )
}
const Content2 = () => {
  return (
    <>
      advokátka
      <br />
      ČAK 11258
      <br />
      <br />
      tel. 774 719 708
      <br />
      email: martanova@ak-martan.cz
      <hr />
      služby v oblasti:
      <ul>
        <li>
          <b>rodinné právo</b> - úprava poměrů k nezletilým dětem, rozvod,
          vypořádání majetku po rozvodu
        </li>
        <li>
          <b>trestní právo</b> - zastupování obětí trestních činů
        </li>
        <li>
          <b>občanské právo</b>
        </li>
      </ul>
    </>
  )
}
const Content3 = () => {
  return (
    <>
      <ul>
        <li>
          právní služby poskytujeme obvykle na základě hodinové sazby ve výši
          1.000,- Kč až 1500,- Kč za hodinu
        </li>
        <li>
          není-li sjednána hodinová sazba, je odměna účtována v souladu s
          vyhláškou č. 177/1996 Sb. advokátní tarif
        </li>
        <li>cena bude sjednána při úvodní konzultaci</li>
      </ul>
    </>
  )
}

//GDPR

// const Content4 = () => {
//   return (
//     <>
//       Pariatur quis enim anim consectetur laborum ea aliquip irure ex
//       adipisicing proident reprehenderit cillum aute. Ullamco culpa occaecat in
//       et Lorem nulla occaecat sint officia est mollit ex aliqua. Sunt
//       adipisicing laboris cupidatat dolor in eiusmod voluptate elit. Sunt aute
//       eu sit duis officia amet.
//     </>
//   );
// };

export {
  Content0,
  Content1,
  Content2,
  Content3,
  // ,  Content4
}
