import React from "react"
import { Col } from "react-bootstrap"
import _ from "lodash"
import { ReactComponent as Arrow } from "../images/Arrow.svg"
import { content } from "./content"
import MobileNav from "./MobileNav"

const MobileFirstSlide = (props) => {
  return (
    <Col className="blueCol" lg={6}>
      <div className="content-col">
        <MobileNav headings={props.headings} />

        <div
          className="slide first-slide"
          id={_.kebabCase(props.headings[0])}
          data-slide="0"
        >
          {content[0]}

          <a href={`#${_.kebabCase(props.headings[1])}`}>
            <Arrow className="arrow" />
          </a>
        </div>
      </div>
    </Col>
  )
}

export default MobileFirstSlide
