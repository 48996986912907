import {
  Content0,
  Content1,
  Content2,
  Content3,

  //GDPR
  // Content4,
} from "./contentComponents"

const headings = [
  "Adresa",
  "JUDr. Pavel Marťán",
  "Mgr. Radka Marťánová",
  "Ceník",
  // GDPR
  // "GDPR",
]

const content = [
  <Content0 />,
  <Content1 />,
  <Content2 />,
  <Content3 />,

  //GDPR
  // <Content4 />,
]

export { headings, content }
