import $ from "jquery";
import gsap from "gsap";

function animations(isUnderLg) {
  //timeline for desktop initial animation
  const tl = gsap.timeline({
    imediateRender: false,
    duration: 0.8,
    ease: "Power2.easeIn",
  });

  //timeline for mobile initial animation
  const tlm = gsap.timeline({
    imediateRender: false,
    duration: 0.8,
    ease: "Power1.easeIn",
  });

  //Scroll triggered up arrow
  const scrollTl = gsap.timeline({
    scrollTrigger: {
      trigger: ".scrollTrig",
      start: "bottom bottom",
      end: "bottom 600vh",
      scrub: 0.1,
    },
  });
  if (isUnderLg) {
    scrollTl.to(".up-arrow-div", {
      duration: 1,
      visibility: "visible",
    });
  }

  //Animation for mobile down arrow
  isUnderLg &&
    gsap.to($(".arrow"), {
      y: "-80%",
      repeat: -1,
      yoyo: true,
      duration: 0.7,
    });

  //Preventing FOUC
  $(window).on("load", () => {
    $("body").css("visibility", "visible");

    //Desktop initial animation
    if (!isUnderLg) {
      tl.from($(".blueCol"), {
        x: "-100%",
        opacity: "-1",
      });
      tl.from(
        $(".brownCol"),
        {
          x: "150%",
          opacity: -1,
        },
        "<"
      );
      tl.from(
        $(".image"),
        {
          x: "100%",
          opacity: "-1",
        },
        "<"
      );

      tl.to(
        $(".first-slide"),
        {
          opacity: "100%",
        },
        "<"
      );
      tl.from($(".gmap_canvas"), { opacity: "-1" }, "<");
    }

    //Mobile intitial animation
    if (isUnderLg) {
      tlm.from($(".first-slide"), { opacity: "-0.7", x: "100%" });
      tlm.from($(".mobile-nav"), { opacity: "-0.7", x: "-100%" }, "<");
    }
  });
}

export default animations;
