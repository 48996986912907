import React from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import { ReactComponent as Logo } from "../images/logo.svg";
import _ from "lodash";

const MobileNav = (props) => {
  return (
    <Navbar
      collapseOnSelect
      className="mobile-nav"
      variant="dark"
      expand={false}
    >
      <Container className="nav-container">
        <Navbar.Brand>
          <Logo className="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-nav" />
        <Navbar.Collapse id="basic-nav">
          {props.headings.map((heading, i) => {
            if (i !== 0) {
              return (
                <Nav.Link href={`#${_.kebabCase(heading)}`} key={i}>
                  {heading}
                </Nav.Link>
              );
            } else {
              return (
                <Nav.Link href="#" key={i}>
                  {heading}
                </Nav.Link>
              );
            }
          })}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default MobileNav;
