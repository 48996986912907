import gsap from "gsap";
import $ from "jquery";

//Function for changing slide when hovering or clicking on heading of desired content
function handleEnter(e) {
  let activeSlide;
  const wantedSlide = e.target;
  const links = document.querySelectorAll("[data-index]");
  const contentCol = $(".content-col");
  const slides = document.querySelectorAll(".slide");

  links.forEach((el) => {
    if (el === wantedSlide) {
      el.style.opacity = "100%";
      el.style.fontSize = "2.3vw";
    } else {
      el.style.opacity = "40%";
      el.style.fontSize = "2.16vw";
    }
  });

  if (wantedSlide !== activeSlide) {
    const transform = -(Number(wantedSlide.dataset.index) * 100);

    gsap.to(contentCol, {
      y: `${transform}vh`,
      duration: 1,
      ease: "out",
    });

    slides.forEach((slide) => {
      if (slide.dataset.slide === wantedSlide.dataset.index) {
        gsap.to(slide, {
          opacity: "100%",
          ease: "Power1.easeIn",
          duration: 1,
        });
      } else {
        gsap.to(slide, { opacity: "40%", duration: 1, ease: "out" });
      }
    });
    activeSlide = wantedSlide;
  }
}

export default handleEnter;
