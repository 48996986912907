import React from "react";
import { ButtonGroup, Button } from "react-bootstrap";

const DesktopNav = (props) => {
  return (
    <ButtonGroup
      vertical="true"
      onMouseOver={props.handleEnter}
      onClick={props.handleEnter}
    >
      {props.headings.map((heading, i) => {
        return (
          <Button className="nav-btn" variant="link" data-index={i} key={i}>
            {heading}
          </Button>
        );
      })}
    </ButtonGroup>
  );
};

export default DesktopNav;
