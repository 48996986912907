import React, { useEffect } from "react";

//for animations
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";

//bootstrap components
import { Row, Col, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

//self-made components
import {
  DesktopNavCol,
  MobileFirstSlide,
  DesktopContentCol,
  MobileContent,
} from "./components/Components";
import "./App.scss";

//content and headings
import { content, headings } from "./components/content";

//logic functions
import { handleEnter, responsiveViewUnit, animations } from "./logic/exports";

//for responsive conditional rendering
import { useMediaQuery } from "react-responsive";

//gsap plugins
gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger);

const App = () => {
  //Media query for conditional rendering
  const isUnderLg = useMediaQuery({ query: "(max-width: 991.8px)" });

  //Changing view-unit based on aspect ratio for better responsiveness
  responsiveViewUnit();

  //Animations
  useEffect(() => {
    animations(isUnderLg);
  });

  return (
    <Container fluid>
      <Row>
        <Col lg={7}>
          <Row>
            {!isUnderLg && (
              <DesktopNavCol handleEnter={handleEnter} headings={headings} />
            )}
            {isUnderLg ? (
              <MobileFirstSlide content={content} headings={headings} />
            ) : (
              <DesktopContentCol content={content} headings={headings} />
            )}
          </Row>

          {isUnderLg && <MobileContent content={content} headings={headings} />}
        </Col>
        {!isUnderLg && <Col className="image" lg={5}></Col>}
      </Row>
    </Container>
  );
};

export default App;
