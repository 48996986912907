import React from "react";
import MobileSlideTemplate from "./MobileSlideTemplate";

const MobileContent = (props) => {
  return (
    <>
      {props.headings.map((heading, i) => {
        if (i === 0) return "";
        return (
          <MobileSlideTemplate
            rowClass={i % 2 === 0 ? "blueCol" : "brownCol"}
            heading={heading}
            content={props.content[i]}
            i={i}
            key={i}
          />
        );
      })}
    </>
  );
};

export default MobileContent;
